.section {
    display: grid;
    grid-template-rows: fit-content(0) auto;
    grid-template-areas:
        "topbar"
        "content";
    background-color: var(--light-gray);
    height: 100%;
    padding: 7px;
    padding-top: 0;
    padding-bottom: 25px;
    box-sizing: border-box;
}

@media (max-width: 1000px) {
    .section {
        width: 100%;
        max-width: 100%;
    }
}
