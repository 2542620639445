.section.home {
    display: flex;
    flex-flow: column;
    padding: 0 0 30px 0;

    .topbar {
        padding: 5px 15px;
    }
}

#home {
    flex-grow: 1;
    overflow: hidden;
    align-items: center;
    gap: 20px;
    padding-top: 0;
    padding-bottom: 65px;
}

.home__section__title-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 15px;
}

.home__section-title {
    flex-grow: 1;
    color: var(--primaryTextColor);
    font-size: 34px;
    font-weight: bold;
}

.home__sudoku-wrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: var(--fill);
    z-index: 4;
}

@media (max-height: 600px) {
    .home__grid {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
    }
}

@media (max-width: 1000px) {
    #home {
        width: 100% !important;
    }
}
