:root {
    --lightGray: #dce0e6;
    --darkGrayBlue: #344861;
}

[data-theme='light'] {
    --red: #ff3b30;
    --orange: #ff9500;
    --yellow: #ffcc00;
    --green: #34c759;
    --blueGreen: #00c7be;
    --lightBlue: #32ade6;
    --darkBlue: #007aff;
    --purple: #af52de;

    --fill: #e9e9e9;
    --darkBackground: white;
    --primaryTextColor: black;
    --secondaryTextColor: #9e9e9e;
    --primaryIconColor: black;
    --secondaryIconColor: #848484;
    --topbarFontColor: var(--darkGrayBlue);
    --bookmarkOffColor: var(--darkGrayBlue);
    --actionSheetCancelBackground: white;
    --actionSheetButtonBorder: #d8d8db;
    --contextMenuButtonColor: #505050;
    --contextMenuButtonBackground: #e7e7e7;
    --editButtonColor: var(--themeColor);
    --editButtonBackground: var(--lightGray);
    --numpadButtonBackground: var(--lightGray);
    --disabledButtonBackground: var(--fill);
    --disabledButtonBorderColor: transparent;
    --settingsItemBackground: white;
    --settingsItemBorder: var(--lightGray);
    --settingsItemColor: var(--darkGrayBlue);
    --bookmarksEmptyColor: #444444;
    --navbarBackground: white;
    --navbarIconColor: #c7c7c7;
    --navbarSelectedTabIconColor: #444444;
    --carouselDotColor: #c8c8c8;
    --settingsBorderColor: #dddddd;

    --editButtonDisabledFilter: brightness(0.9);

    /* This color should be equal to the color of the container behind it */
    --checkIconColor: white;

    --settingsItemDisabledFilter: brightness(1.4);
    --navbarBackdropColor: #aaa;
    --actionSheetBackground: #eeeef0;
}

[data-theme='dark'] {
    --red: #ff453a;
    --orange: #ff9f0a;
    --yellow: #ffd60a;
    --green: #30d158;
    --blueGreen: #66d4cf;
    --lightBlue: #64d2ff;
    --darkBlue: #0a84ff;
    --purple: #bf5af2;

    --fill: black;
    --darkBackground: #1c1c1e;
    --primaryTextColor: white;
    --secondaryTextColor: gray;
    --primaryIconColor: white;
    --secondaryIconColor: #848484;
    --topbarFontColor: white;
    --bookmarkOffColor: darkgray;
    --actionSheetBackground: #1f1e1e;
    --actionSheetCancelBackground: #2c2c2e;
    --actionSheetButtonBorder: #434344;
    --contextMenuButtonColor: #959595;
    --contextMenuButtonBackground: #2b2b2b;
    --editButtonColor: #75747c;
    --editButtonBackground: var(--darkBackground);
    --editButtonDisabledFilter: brightness(0.4);
    --numpadButtonBackground: var(--darkBackground);
    --disabledButtonBackground: var(--fill);
    --disabledButtonBorderColor: #25242c;
    --canvasLightDefaultCellColor: #25242c;
    --canvasDarkDefaultCellColor: #161620;
    --canvasCellBorderColor: black;
    --canvasBoxBorderColor: black;
    --canvasClueColor: #75747c;
    --canvasSolutionColor: #6f90c3;
    --canvasSelectedCellBackground: #153b79;
    --canvasSameValueCellBackground: #0f0e12;
    --canvasNoteHighlightColor: white;
    --canvasAnimationBaseColor: 255, 255, 255;
    --settingsItemBackground: #25242c;
    --settingsItemBorder: black;
    --settingsItemTitleColor: white;
    --bookmarksEmptyColor: #616161;
    --checkIconColor: black;
    --navbarBackground: #25242c;
    --navbarIconColor: #616161;
    --navbarSelectedTabIconColor: white;
    --carouselDotColor: #949494;
    --settingsBorderColor: #333333;
    --settingsItemDisabledFilter: brightness(0.6);
    --navbarBackdropColor: black;
}

[data-accent-color='red'] {
    --themeColor: var(--red);
}

[data-accent-color='orange'] {
    --themeColor: var(--orange);
}

[data-accent-color='yellow'] {
    --themeColor: var(--yellow);
}

[data-accent-color='green'] {
    --themeColor: var(--green);
}

[data-accent-color='blueGreen'] {
    --themeColor: var(--blueGreen);
}

[data-accent-color='lightBlue'] {
    --themeColor: var(--lightBlue);
}

[data-accent-color='darkBlue'] {
    --themeColor: var(--darkBlue);
}

[data-accent-color='purple'] {
    --themeColor: var(--purple);
}

html,
body,
#root,
.app {
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    user-select: none;
    touch-action: manipulation;
    scroll-behavior: smooth;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Inter, sans-serif;
}

.app {
    background-color: var(--fill);
}

body {
    position: fixed;
}

a {
    text-decoration: none;
}

p {
    margin: 0;
}

@media (min-aspect-ratio: 21 / 20) {
    .app {
        display: grid;
        place-content: center;
    }
}
