#statistics {
    height: 100%;
    overflow-y: auto;
}

.home__statistics-list {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    gap: 20px;
    padding: 0 5px;
    margin-top: 20px;
    color: var(--primaryTextColor);
}

.home__statistics-list__item {
    display: flex;
    flex-flow: column;
    border: solid 1px gray;
    padding: 10px;
    border-radius: 10px;
}

.home__statistics-list__item__title {
    font-size: 24px;
}

.home__statistics-list__item__difficulty-wrapper {
    grid-area: difficulty;
    display: flex;
    flex-flow: row;
    align-items: center;
    font-size: 20px;
}

.home__statistics-list__item__difficulty-name {
    font-size: 18px;
    text-align: center;
    width: 100%;
}

.home__statistics-list__item__data {
    grid-area: data;
    display: flex;
    flex-flow: column;
    gap: 5px;
    font-size: 14px;
    padding-top: 10px;
}

.home__statistics-list__item__data__item {
    display: grid;
    grid-template-columns: auto fit-content(0);
}
