.home__settings {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.home__settings-wrapper {
    flex-grow: 1;
    padding: 0 10px;
    padding-top: 10px;
}
