.topbar {
    grid-area: topbar;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.topbar__top {
    display: grid;
    grid-template-columns: 1fr fit-content(0) 1fr;
    align-items: center;
    width: 100%;
    min-height: 37px;
    overflow-x: hidden;
}

.topbar__top__left {
    display: flex;
    align-items: center;
}

.topbar__top__right {
    display: flex;
    justify-content: flex-end;
}

.topbar__top__back {
    display: grid;
    place-items: center;
    box-sizing: border-box;
    margin-right: 8px;
}

.topbar__bottom {
    width: 100%;
}

.topbar__title-wrapper {
    color: var(--topbarFontColor);
    white-space: nowrap;
    margin-right: 10px;
    width: fit-content;
}

.topbar__title {
    font-size: 22px;
}

.topbar__title-wrapper.subtitle {
    display: flex;
    flex-flow: column;
}

.topbar__title-wrapper.subtitle .topbar__title {
    font-size: 14px;
    color: gray;
}

.topbar__top__additional {
    display: grid;
    justify-content: center;
    gap: 10px;
}

.topbar__button {
    display: grid;
    place-items: center;
    color: var(--themeColor);
    font-size: 20px;
    margin-right: 10px;
    white-space: nowrap;
}

.topbar__button:last-child {
    margin-right: 0;
}

.topbar__logo {
    height: 16px;
}

@media (min-aspect-ratio: 21 / 20) {
    .topbar__top {
        width: 100%;
    }
}
