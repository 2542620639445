.play-button {
    position: absolute;
    transform: translate(-50%, 0);
    display: grid;
    place-items: center;
    color: white;
    font-size: 25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: top 0s;
    z-index: 3;
    border: solid var(--fill) 5px;
}

.play-button.defaultVariant {
    background-color: var(--themeColor);
    left: 50%;

    .play-button__icon {
        transform: translate(2px);
    }
}

.play-button.confirmVariant {
    border: none;
    background-color: var(--green);
    left: 75%;
}

.play-button.cancelVariant {
    border: none;
    background-color: var(--red);
    left: 25%;
}
