.action-sheet__wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    height: fit-content !important;
    padding-bottom: 0;
    max-width: 450px;
}

.action-sheet__handle {
    background-color: darkgray;
    width: 50px;
    height: 6px;
    border-radius: 3px;
}

.action-sheet__top {
    display: grid;
    grid-template-columns: 1fr fit-content(0) 1fr;
    width: calc(100% - 20px);
    padding: 0 10px;
    align-items: center;
}

.action-sheet__top__back {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 5px;
    color: var(--themeColor);
    font-size: 18px;
}

.action-sheet__wrapper.buttonsMode {
    width: calc(100% - 30px);
    padding: 0 15px;
    padding-bottom: 20px;
}

.action-sheet__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    max-width: 450px;
    background-color: var(--actionSheetBackground);
    border-radius: 15px;
    padding: 10px 0;
}

.action-sheet__list {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    max-width: 450px;
    background-color: var(--actionSheetBackground);
    border-radius: 15px;
    margin-bottom: 15px;
}

.action-sheet__list__title {
    display: grid;
    place-items: center;
    border-radius: 15px 15px 0 0;
    background-color: var(--actionSheetBackground);
    color: var(--secondaryTextColor);
    padding: 10px;
    text-align: center;
}

.action-sheet__list__handle {
    background-color: lightgray;
    height: 5px;
    width: 50px;
    border-radius: 2.5px;
    margin-bottom: 7px;
    margin-top: 7px;
}

.context-menu__button {
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
    width: 100%;
    font-size: 20px;
    color: var(--contextMenuButtonColor);
    background-color: var(--contextMenuButtonBackground);
    border-radius: 17px;
    padding: 11px;
    box-sizing: border-box;
}

.context-menu__button.vertical {
    display: flex;
    flex-flow: column;
    gap: 5px;
    align-items: center;
    font-size: 14px;
}
