.button {
    display: grid;
    place-items: center;
    padding: 7px;
    border-radius: 10px;
    font-size: 22px;
}

.button.disabled {
    filter: brightness(80%);
}

.button.ghost {
    height: fit-content;
    font-size: 16px;
    padding: 5px 20px;
    margin-bottom: 0;
}
