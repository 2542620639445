.edit-buttons__button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 30px;
    cursor: pointer;
    color: var(--editButtonColor);
    background-color: var(--editButtonBackground);
    transition: all 0.1s ease-in-out;
}

.edit-buttons__button.disabled {
    filter: var(--editButtonDisabledFilter);
    cursor: default;
}

.edit-buttons__button.highlight {
    background-color: var(--themeColor);
    color: white;
}

.edit-buttons__button.yellow {
    background-color: #fed330;
}

.edit-buttons__button__digit {
    height: 35%;
}

.edit-buttons__button.number {
    flex-flow: column;
    gap: 5px;

    .icon {
        width: 30%;
    }
}
