.numpad__button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--themeColor);
    font-size: 50px;
    border-radius: 10px;
    cursor: pointer;
}

.numpad__button:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
}

.numpad__button.number {
    background-color: var(--numpadButtonBackground);
}

.numpad__button.disabled {
    background-color: var(--disabledButtonBackground);
    border-color: var(--disabledButtonBorderColor);
    border-style: solid;
    border-width: 1px;
    cursor: default;
}

.numpad__button.disabled:hover {
    background-image: none;
}

.numpad__button.hidden {
    visibility: hidden;
}

.numpad__button.locked {
    border-width: 1px;
    border-style: solid;
    color: white;
    background-color: var(--themeColor);
    border-color: var(--themeColor);
}

.numpad__button.color {
    color: white;
}

.numpad__button__digit {
    height: 50%;
}
